import React, { createContext, useContext, useState } from "react";
import jwt_decode from "jwt-decode";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export function UserProvider(props) {
  // const [toast, setToast] = useState(0)
  const access_token = localStorage.getItem('access_token')
  let decoded = { user: { email: 'unknown', username: 'unknown' } };
  if (access_token)
    decoded = jwt_decode(access_token);
  return <UserContext.Provider value={decoded}>
    {props.children}
  </UserContext.Provider>
}
