const config = {
    // REACT_APP_SERVER_API: 'https://s3backend.iran.liara.run/',
    // REACT_APP_SERVER_API: 'http://oneclick2.com/',

}
const env = (key) => {
    // console.log(process.env[key], config[key]);
    if (config.hasOwnProperty(key))
        return config[key];
    if (process.env.hasOwnProperty(key))
        return process.env[key];
    return null;
};

module.exports = {
    REACT_APP_SERVER_API: "REACT_APP_SERVER_API",
    REACT_APP_PAGINATION_BUTTON_COUNT:"REACT_APP_PAGINATION_BUTTON_COUNT",
    env
}
