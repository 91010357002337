import React, { createContext, useContext, useEffect, useState } from "react";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);
export function ThemeProvider(props) {

  const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'theme-dark')
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme])


  return <ThemeContext.Provider value={{ theme, setTheme }}>
    {props.children}
  </ThemeContext.Provider>
}
