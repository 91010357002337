import { cifAz, cifRu, cifUs } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
const lngs = {
  en: { nativeName: 'English', icon: cifUs },
  az: { nativeName: 'Azerbaijan', icon: cifAz },
  ru: { nativeName: 'Russia', icon: cifRu },
};

function LanguageChanger() {

  const { t } = useTranslation();
  return (
    <CDropdown variant="btn-group">
      <CDropdownToggle id='language-dropdown' color='light text-capitalize'>{t(i18n.resolvedLanguage)}</CDropdownToggle>
      <CDropdownMenu id='language-menu'>
        {Object.keys(lngs).map((lng) =>
          <CDropdownItem key={lng} onClick={() => i18n.changeLanguage(lng)}>
            <CIcon size='lg' icon={lngs[lng].icon} className={'me-2'} />
            {/* {lngs[lng].nativeName} */}
            {t(lng)}
          </CDropdownItem>
        )}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default LanguageChanger
