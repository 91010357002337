import React from 'react'
import { Content, Header, Footer } from 'src/components/home';
import { useTheme } from 'src/providers/Theme';


const HomeLayout = () => {
  const { theme } = useTheme();
  return (
    <div data-theme={theme} id='page'>
      <Header />
      <div className={"body flex-grow-1 px-3 " + theme}>
        <Content />
      </div>
      <Footer />
    </div>
  )
}

export default HomeLayout;
