import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";



const ModalContext = createContext();
export const useModal = () => useContext(ModalContext);

export function ModalProvider(props) {
    const [modal, setModal] = useState({ title: 'title', body: 'it is modal body' })
    const [visible, setVisible] = useState(false);
    return <ModalContext.Provider value={{ modal, setModal, visible, setVisible }} >
        <Modal modal={modal} visible={visible} setVisible={setVisible} />
        {props.children}
    </ModalContext.Provider>
}
export function Modal({ modal, visible, setVisible }) {
    const { t } = useTranslation();
    return <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
            <CModalTitle>{modal.title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{modal.body}</CModalBody>
        <CModalFooter>
            <CButton color="primary"
                onClick={() => setVisible(false)}
            >
                {t('close')}
            </CButton>
        </CModalFooter>
    </CModal>
}