import { cibFacebook, cibInstagram, cilIndustrySlash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React from 'react'
import { styler } from 'src/common'
import s from './footer.module.css'

function Footer() {
  return <div className='mt-3'></div>
  return (
    <footer className={styler(s,'footer')}>
      <div className={styler(s,'container-fluid footer-container')}>
        <div className={styler(s,'container')}>
            <div className={styler(s,'footer')}>
              <div className={styler(s,'row justify-content-md-between')}>
                <div className={styler(s,'col-lg-8 footer-content1')}>
                  <div className={styler(s,'row')}>
                    <div className={styler(s,'d-flex flex-row flex-wrap top-part mb-3')}>
                      <a href="/3dmodels/show/war_robot" rel="nofollow">Polygon Expert</a>
                      <a rel="nofollow" href="/feedbacks/new">Support</a>
                      <a rel="nofollow" href="/faq/category/10000/show">Rules</a>
                      <a rel="nofollow" href="/user/account">Buy 3D models</a>
                    </div>
                  </div>
                  <div className={styler(s,'row')}>
                    <div className={styler(s,'bottom-part')}>
                      <a rel="nofollow" href="/faq/category/10005/show">Terms of use</a>
                      <a rel="nofollow" href="/faq/category/10006/show">Privacy policy</a>
                    </div>
                  </div>
                </div>
                <div className={styler(s,'col-lg-4 footer-content2')}>
                  {/* <div className={styler(s,'row')}>
                    <div className={styler(s,'d-flex flex-row justify-content-start justify-content-lg-end top-part mb-4 social-icons')}>
                      <div>
                        <a href="https://www.facebook.com/www.3dsky.org" target="_blank" rel="noreferrer">
                          <CIcon icon={cibFacebook}></CIcon>
                        </a>
                      </div>
                      <div >
                        <a href="https://instagram.com/3dsky_org" target="_blank" rel="noreferrer">
                          <CIcon icon={cibInstagram}></CIcon>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  <div className={styler(s,'row')}>
                    <div className={styler(s,'bottom-part')}>
                      <div className={styler(s,'mb-0')}>
                        <span className={styler(s,'mr-2')}>© 3dsky.org, 2023</span>
                      </div>
                      <div > 3DWORLD LTD., 7-9 Riga Feraiou, LIZANTIA COURT, <br id="br-office-en" /> Office 310 <br /> Agioi Omologites, Nicosia, Cyprus </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </footer>)
}

export default Footer