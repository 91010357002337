import React, { createContext, useContext, useState } from "react";
import { CToast, CToastBody, CToastClose, CToaster } from "@coreui/react"

const ToastContext = createContext();
export const useToast = () => useContext(ToastContext);
export const simpleToast = (message, color,autohide=true) => <CToast autohide={autohide} visible={true} color={color} className="text-white align-items-center">
  <div className="d-flex">
    <CToastBody>{message}</CToastBody>
    <CToastClose className="me-2 m-auto" white />
  </div>
</CToast>

export function ToastProvider(props) {
  const [toast, setToast] = useState(0);

  return <ToastContext.Provider value={{ toast, setToast }}>
    <CToaster push={toast} placement="top-end" />

    {props.children}</ToastContext.Provider>
}
