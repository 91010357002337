const { default: i18n } = require("./i18n");

const getHeaders = () => ({
    Authorization: "Bearer " + localStorage.getItem('access_token'),
    Language: i18n.resolvedLanguage
})

const getLanguageHeader = () => ({ Language: i18n.resolvedLanguage })

function styler(s, classes) {
    return classes.split(' ').map(i => i in s ? s[i] : i).join(' ');
}

const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
}
module.exports = {
    getHeaders,
    styler,
    getLanguageHeader,
    logout
}