import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import { ToastProvider } from './providers/Toaster'
import HomeLayout from './layout/HomeLayout'
import './scss/style.scss'
import { UserProvider } from './providers/User'
import jwt_decode from "jwt-decode";

import './html5doctor.css';
import './Bootstrap.css';
// import './Header.css';
// import './Footer.css';
import './App.css';
import { ModalProvider } from './providers/Modal'
import { ThemeProvider } from './providers/Theme'
// import './Profile.css';
// import './Models.css';
// import './Single.css';

// import './Full.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Forgot = React.lazy(() => import('./views/pages/forgot/Forgot'))
const Reset = React.lazy(() => import('./views/pages/reset/Reset'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const isAuthorized = () => {
  if (localStorage) {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      return true;
    }
  }
  return false;
}

const isAdminAuthorized = () => {
  if (localStorage) {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      const { user: { is_admin } } = jwt_decode(access_token);
      if (is_admin)
        return true;
    }
  }
  return false;
}

function PrivateRoute({ children }) {

  if (!isAuthorized()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />
  }

  // authorized so return child components
  return children;
}

function AdminRoute({ children }) {

  if (!isAdminAuthorized()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />
  }
  // authorized so return child components
  return children;
}
class App extends Component {

  render() {
    console.log('React app rendered');
    return (
      <ThemeProvider>
        <ToastProvider>
          <UserProvider>
            <ModalProvider>
              <HashRouter>
                <Suspense fallback={loading}>
                  <Routes>
                    <Route exact path="/forgot" name="Reset Password" element={<Forgot />} />
                    <Route exact path="/reset/:uuid" name="Change Password" element={<Reset />} />
                    <Route exact path="/login" name="Login Page" element={isAuthorized() ? <Navigate to="/models" /> : <Login />} />
                    <Route exact path="/register" name="Register Page" element={isAuthorized() ? <Navigate to="/models" /> : <Register />} />
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                    <Route path="/*" name="Home" element={<PrivateRoute><HomeLayout /></PrivateRoute>} />
                    <Route path="admin/*" name="Admin" element={<AdminRoute><DefaultLayout /></AdminRoute>} />
                  </Routes>
                </Suspense>
              </HashRouter>
            </ModalProvider>
          </UserProvider>
        </ToastProvider>
      </ThemeProvider>
    )
  }
}

export default App
